<script>
import { ViewAllActivities } from 'aether-activities-vue2';

export default {
    components: { ViewAllActivities },
    data() {
        return {
            currentOwner: {},
            regarding: {},
            currentContact: {}
        }
    },
    mounted() {
        this.currentContact = {
            pidm: sessionStorage.getItem('pidm'),
            name: sessionStorage.getItem('contactName')
        };
        this.currentOwner = {
            pidm: localStorage.getItem('userPidm'),
            name: localStorage.getItem('userFullName')
        };
        this.regarding = {
            id: sessionStorage.getItem('pidm'),
            type: 'Contact',
            name: sessionStorage.getItem('contactName')
        };
    }
}
</script>

<template>
    <ViewAllActivities :key="regarding.id" :currentContact="currentContact" :currentOwner="currentOwner"
        :regardingObj="regarding" />
</template>

<style scoped>
.v-card.on-hover.theme--dark {
    background-color: rgb(70, 69, 69);
}

.v-card.on-hover {
    background-color: #9fcbed;
}

.show-btn-chev {
    color: rgb(0, 0, 0) !important;
    display: block !important;
}

.show-btn {
    color: rgb(0, 0, 0) !important;
    display: block !important;
    background-color: rgba(255, 255, 255) !important;
}

.show-btn.on-hover {
    color: rgb(0, 0, 0) !important;
    display: block !important;
    background-color: rgb(175, 174, 174) !important;
}

#open-btn {
    position: inherit;
    float: right;
    margin-top: -10px;
    display: none;
}

#chevron-btn {
    position: inherit;
    float: left;
    /* margin-top: -10px; */
    display: none;
    max-width: 50px;
    margin: auto;
    padding-left: 15px;
    padding-top: 10px;
    margin-left: 7px;
}

/* .v-card {
    transition: opacity .4s ease-in-out;
}

.v-card:not(.on-hover) {
    opacity: 0.6;
} */

.one-line {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    white-space: normal;
}

.roundedStatus {
    border-radius: 25px;
    background-color: rgba(94, 86, 105, 0.08);
    padding: 6px;
}

.roundedStatusOpen {
    background-color: rgb(42, 199, 2, .3);
}

.closedActivity {
    color: rgb(42, 199, 2);
}

.activityCard {
    margin-bottom: 20px
}

.commentIcon {
    max-width: 30px !important;
    /* padding-left: 25px !important; */
}

.noteTitle {
    font-size: 1rem !important;
    cursor: pointer;
}

.commentTitle {
    font-size: 1rem !important;
}

.commentSubject {
    font-size: 1rem !important;
    color: darkgrey;
}

.noteSubject {
    font-size: 1rem !important;
}

.showMore {
    color: lightgray;
}

.noteDate {
    text-align: right;
    color: darkgrey;
}

.comment {
    padding-right: 15px;
    padding-left: 15px;
}

.newComment {
    padding-left: 25px;
    padding-bottom: 25px;

}

.statecode {
    margin-top: -4px;
}
</style>